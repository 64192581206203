<template>
  <div class="sourceForm">
    <div class="bottom-wrap">
      <div>
        <el-menu
          :default-active="activeIndex"
          :default-openeds="opendes"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-submenu
            v-for="(item, index) in selectList"
            :key="index"
            :index="index.toString()"
          >
            <template slot="title">{{ item.parentName }}</template>
            <el-menu-item
              @click="handleSelect(index, ind, type)"
              :index="index + '-' + ind.toString()"
              v-for="(ite, ind) in item.childrens"
              :key="ind"
              >{{ ite.name }}</el-menu-item
            >
          </el-submenu>
        </el-menu>
      </div>
      <div class="title">{{ title }}</div>
      <search-data
        ref="search"
        :title="'查询：'"
        :placeholder="'应用名称'"
        @goNextEvent="goNext"
        @goNextAdd="goAdd"
        :content="searchAry"
        :height="'height:70%'"
        :search-url="getSourceList"
        :showTitle="showLabel"
        :btn-text="'添加数据源'"
        :params="params"
        @del="deleteSource"
        :showAry="showAry"
        :isShowOpear="true"
        @getTaskStatus="getTastStatus(arguments)"
        @goViewInfo="goViewInfo"
        :isOtherFileds="true"
        :otherFildeShowAry="fildeAarry"
        :otherFildeShowTitle="fildeTitle"
      ></search-data>
      <v-dialog
        title="查看数据"
        sureTxt="关闭"
        v-model="isShowDialog"
        class="dialog"
        @confirm="confirm"
        :showCancelButton="false"
        oStyle="justify-content: flex-end"
      >
        <v-table
          :tableData="tableList"
          ref="vTable"
          :isOperateColumn="false"
          :headers="headers"
        >
        </v-table>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import searchData from "../components/search/index.vue";
import {
  getSourceList,
  getSelectList,
  removeSource,
  getTastStatus,
  getSourceData,
} from "./api.js";
export default {
  name: "sourceDataAdd",
  components: {
    searchData,
  },
  data() {
    return {
      fildeAarry: ["mode"],
      fildeTitle: {
        mode: "同步方式",
      },
      activeIndex: "0-0",
      opendes: ["0"],
      type: "编辑",
      searchAry: [], //列表数据
      selectList: [], //菜单数据
      title: "",
      name: "",
      getSourceList,
      params: {
        curPage: 1,
        pageSize: 12,
        name: undefined,
        type: undefined,
      },
      showAry: ["url", "username", "sql"],
      showLabel: {
        url: "数据库地址",
        username: "用户名",
        sql: "查询语句",
      },
      isShowDialog: false,
      requestList: [], // 表格数据
    };
  },
  created() {
    this.getSelectList();
  },
  mounted() {
    // this.$nextTick(() => {
    //   if (document.getElementById("scrollWrap")) {
    //     const selectWrap = document.getElementById("scrollWrap");
    //     selectWrap.addEventListener("scroll", this.scrollLoadMore);
    //   }
    // });
  },
  computed: {
    tableList() {
      // 处理数据
      let newArr = [];
      this.requestList.forEach((item) => {
        let obj = [];
        item.forEach((items) => obj.push([items.field, items.value]));
        newArr.push(Object.fromEntries(obj));
      });
      return newArr;
    },
    // 处理表头数据
    headers() {
      let headerList = [];
      this.tableList.forEach((item) => {
        headerList = Object.keys(item);
      });
      const newHeaderList = [...new Set(headerList)];
      let data = [];
      newHeaderList.forEach((item) => {
        data.push({
          prop: item,
          label: item,
        });
      });
      return data;
    },
  },
  methods: {
    //点击暂停播放按钮
    getTastStatus(params) {
      console.log("params=====>", params);
      this.$axios
        .post(`${getTastStatus}?id=${params[0]}&operate=${params[1]}`, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code == 200) {
            this.$refs.search.getList(this.params);
          }
        });
    },
    // 查看信息
    goViewInfo(item) {
      this.isShowDialog = true;
      this.requestList = [];
      this.$axios
        .get(`${getSourceData}?id=${item.id}`, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code == 200) {
            this.requestList = res.data.datas;
          }
        });
    },
    handleSelect(first, children, type) {
      this.activeIndex = first + "-" + children.toString();
      this.opendes[0] = children;
      this.title = this.selectList[first].childrens[children].name;
      this.type = this.selectList[first].childrens[children].type;
      this.params.type = this.selectList[first].childrens[children].type;
      this.$refs.search.getList(this.params);
    },
    getSelectList() {
      this.$axios.get(`${getSelectList}`).then((res) => {
        if (res.code === 200) {
          this.selectList = res.data;
          this.title = this.selectList[0].childrens[0].name;
          this.type = this.selectList[0].childrens[0].type;
          this.params.type = this.selectList[0].childrens[0].type;
          this.$refs.search.getList(this.params);
        }
      });
    },
    deleteSource(id) {
      this.$axios
        .post(`${removeSource}?ids=${id}`, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code == 200) {
            this.params.curPage = 1;
            this.$refs.search.getList(this.params);
          }
        });
    },
    search(data) {
      this.searchData = data;
    },
    goNext(item) {
      this.$router.push({
        name: `sourceDataAdd`,
        query: {
          id: item.id,
        },
      });
    },
    goAdd() {
      this.$router.push({
        name: `sourceDataAdd`,
        query: {
          type: this.type,
          title: this.title,
        },
      });
    },
    // 点击确定关闭弹窗
    confirm() {
      this.isShowDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.sourceForm {
  // ::-webkit-scrollbar {
  //   display: none; /* Chrome Safari */
  // }
  box-sizing: border-box;
  height: 100%;
  .bottom-wrap {
    padding: 50px 20px;
    height: calc(100% - 150px);
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    margin-top: 30px;
  }
  .v-dialog {
    ::v-deep .el-table {
      min-height: 480px;
      .cell {
        white-space: nowrap;
      }
    }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        // 轨道颜色
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        // 滚动块颜色
        background-color: #e6e9ed;
        // border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        // hover高亮
        background-color: #d5d8db;
      }
    }
  }
}
</style>
